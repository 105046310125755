<script lang="ts" setup>
// Dependencies - Vendor
import { onMounted, onUnmounted } from 'vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineProps({
    isOpaque: { default: true, required: false, type: Boolean }
});

// Non-Reactive Variables
let activeElementBeforeMask: HTMLElement | null = null;
let firstFocusableElement: HTMLElement | null = null;
let lastFocusableElement: HTMLElement | null = null;

// Lifecycle Event Handlers
onMounted(() => {
    document.body.style.overflow = 'hidden'; // Stop background content from scrolling when mask dialog is open.
    trapFocusInsideMask();
});
onUnmounted(() => {
    document.body.style.overflow = 'auto'; // Stop background content from scrolling when mask dialog is open.
    handleReleaseFocusTrap();
});

// UI Event Handlers - Release Focus Trap
const handleReleaseFocusTrap = (): void => {
    // Remove the keydown event listener when the mask is closed.
    document.removeEventListener('keydown', handleTrapTabKey);
    document.removeEventListener('focusout', handleTrapFocus);

    // Restore the focus to the element that had focus before the mask was opened.
    if (activeElementBeforeMask) {
        focusOnElement(activeElementBeforeMask);
    }
};

// UI Event Handlers - Trap Focus
const handleTrapFocus = (event: FocusEvent): void => {
    // If focus goes outside the mask, bring it back to the first element within the mask.
    if (!document.getElementById('maskContentPanel')?.contains(event.relatedTarget as Node)) {
        if (firstFocusableElement) {
            focusOnElement(firstFocusableElement);
        }
    }
};

// UI Event Handlers - Trap Tab Key
const handleTrapTabKey = (event: KeyboardEvent): void => {
    if (event.key === 'Tab') {
        // Check if Shift key is pressed for reverse tabbing.
        if (event.shiftKey) {
            // If the focus is on the first element, move it to the last focusable element.
            if (document.activeElement === firstFocusableElement) {
                event.preventDefault();
                if (lastFocusableElement) {
                    focusOnElement(lastFocusableElement);
                }
            }
            return;
        }

        // If the focus is on the last element, move it to the first focusable element.
        if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            if (firstFocusableElement) {
                focusOnElement(firstFocusableElement);
            }
        }
    }
};

// Utilities - Focus on Element
const focusOnElement = (element: HTMLElement | null): void => {
    if (element) {
        element.focus();
    }
};

// Utilities - Trap Focus Inside Mask
const trapFocusInsideMask = (): void => {
    // Store the last focused element outside the mask.
    activeElementBeforeMask = document.activeElement instanceof HTMLElement ? document.activeElement : null;

    // Get all focusable elements within the mask.
    const focusableElements: HTMLElement[] = Array.from(document.getElementById('maskContentPanel')!.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'));

    // Focus the first element within the mask.
    firstFocusableElement = focusableElements[0];
    lastFocusableElement = focusableElements[focusableElements.length - 1];

    // Add keydown event listener to trap focus within the mask.
    document.addEventListener('keydown', handleTrapTabKey);

    // Add a focusout event listener to trap focus within the mask.
    document.addEventListener('focusout', handleTrapFocus);
};
</script>

<template>
    <!-- Mask Panel - Fills the browser window with a mask. -->
    <div class="fixed left-0 top-0 flex h-full w-full" :class="isOpaque ? 'bg-backdrop-light-opaque dark:bg-backdrop-dark-opaque' : 'bg-backdrop-light dark:bg-backdrop-dark'">
        <!-- The mask content panel sets the maximum height and width for the enclosed content. It also centers any content vertically and horizontally.
             The enclosed content is responsible for its own formatting (background, border, height, overflow, rounding, width...). -->
        <div id="maskContentPanel" class="flex h-full w-full flex-col">
            <slot />
        </div>
    </div>
</template>
