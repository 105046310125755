// Dependencies - Vendor
import { defineStore } from 'pinia';
import { ref, shallowRef, useId } from 'vue';

// Dependencies - Framework
import type { ConnectionConfig } from '@datapos/datapos-share-core';
import type { ToolPanelId } from '@/views/toolMenu/ToolTabs.vue';

// Dependencies - Component
import type { AlertConfig } from '@/components/Alert.vue';

// Interfaces/Types - Appearance
export interface Appearance {
    id: AppearanceId;
    isDark?: boolean;
    nextId: AppearanceId;
}
type AppearanceId = 'dark' | 'light' | 'system';

// Interfaces/Types - Authorisation Flow  UI (Input Error, State)
export interface AuthFlowUIInputError {
    error: {
        code: string;
        message: String;
    };
    max_length?: number;
    min_length?: number;
    name?: string;
    required?: boolean;
    type?: string;
    value?: string;
}
export type AuthFlowUIState = 'chooseMethod' | 'enterPasscode' | 'enterPassword' | 'enterHandle' | 'manageAccess' | 'manageAccount' | 'reauthorise' | 'signIn' | 'signUp';

// Interfaces/Types - Drawer
type DrawerStateId = 'collapsed' | 'expanded' | 'floating';
type DrawerWidthId = 'narrow' | 'wide';

// Interfaces/Types - MainStore
type MainStore = ReturnType<typeof useMainStore>; // TODO

// Interfaces/Types - User
export interface User {
    id: string;
    emailAddress?: string;
    key?: string;
}

// Constants
const DEFAULT_DELAY = 200;
const DRAWER_MAXIMUM_WIDTH = 640;
const DRAWER_MEDIAN_WIDTH = 320;
const DRAWER_MINIMUM_WIDTH = 74;
const LOCAL_STORAGE_APPEARANCE_ID = 'datapos-workbench-appearance-id';
const LOCAL_STORAGE_OPTION_DRAWER_WIDTH_ID = 'datapos-workbench-option-drawer-width-id';
const LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID = 'datapos-workbench-tool-drawer-panel-id';
const LOCAL_STORAGE_TOOL_DRAWER_STATE_ID = 'datapos-workbench-tool-drawer-state-id';
const LOCAL_STORAGE_TOOL_DRAWER_WIDTH_ID = 'datapos-workbench-tool-drawer-width-id';
const WINDOW_BREAK_POINT = 1280;

// Utilities
const getDrawerStateId = (localStorageId: string): DrawerStateId => {
    return window.localStorage.getItem(localStorageId) === 'expanded' ? 'expanded' : 'collapsed';
};
const getDrawerWidthId = (localStorageId: string): DrawerWidthId => {
    return window.localStorage.getItem(localStorageId) === 'wide' ? 'wide' : 'narrow';
};

// Main Store - Compose & Expose
export const useMainStore = defineStore('main', () => {
    // Alert - Properties & Variables
    const alertConfigs = ref<AlertConfig[]>([]); // This cannot be shallow as individual timers are added and removed as required.

    // Alert - Show
    const showAlert = (alertConfig: AlertConfig): void => {
        alertConfigs.value.push({ ...alertConfig, id: useId() });
    };

    // Appearance - Properties & Variables
    const appearance = shallowRef<Appearance>({ id: (window.localStorage.getItem(LOCAL_STORAGE_APPEARANCE_ID) || 'system') as AppearanceId, nextId: 'light' });

    // Appearance - Apply - Using Id
    const applyAppearanceUsingId = (id: AppearanceId): void => {
        switch (id) {
            case 'dark':
                window.document.documentElement.classList.add('dark');
                appearance.value = { id, isDark: true, nextId: 'system' };
                break;
            case 'light':
                window.document.documentElement.classList.remove('dark');
                appearance.value = { id, isDark: false, nextId: 'dark' };
                break;
            default: {
                const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                applyAppearanceUsingMode(isDark);
                appearance.value = { id, isDark, nextId: 'light' };
                break;
            }
        }
        localStorage.setItem(LOCAL_STORAGE_APPEARANCE_ID, id);
    };

    // Appearance - Apply - Using Mode
    const applyAppearanceUsingMode = (isDark: boolean): void => {
        if (isDark) {
            window.document.documentElement.classList.add('dark');
            return;
        }
        window.document.documentElement.classList.remove('dark');
    };

    // Appearance - Apply - Initialise
    applyAppearanceUsingId(appearance.value.id);

    // Appearance - Apply - Watch For System Change
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event): void => {
        if (appearance.value.id !== 'system') {
            return;
        }
        applyAppearanceUsingMode(event.matches);
    });

    // Authorisation Flow UI - Properties & Variables
    const authFlowUIInputErrors = shallowRef<AuthFlowUIInputError[]>([]);
    const authFlowUIState = ref<AuthFlowUIState | undefined>(undefined);

    // Component - Properties & Variables
    const connectionConfigs = shallowRef<ConnectionConfig[]>([]);

    // Internet - Properties & Variables
    const internetConnectionIsOnline = ref<boolean | undefined>(undefined);

    // Navigation - Properties & Variables
    const navigationDepth = ref<number>(0);

    // Option Drawer - Properties & Variables
    const optionDrawerPanelId = ref<string>('');
    const optionDrawerStateId = ref<string>('expanded');
    const optionDrawerWidthId = ref<DrawerWidthId>(getDrawerWidthId(LOCAL_STORAGE_OPTION_DRAWER_WIDTH_ID));
    const optionDrawerWidth = ref<number>(optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH);
    const optionDrawerMenuWidth = ref<number>(optionDrawerWidth.value);

    // Option Drawer - Collapse
    const collapseOptionDrawer = (): void => {
        optionDrawerWidth.value = 0;
        optionDrawerMenuWidth.value = DRAWER_MINIMUM_WIDTH;
        optionDrawerStateId.value = 'collapsed';
    };

    // Option Drawer - Float
    const floatOptionDrawer = (): void => {
        optionDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
        optionDrawerMenuWidth.value = DRAWER_MEDIAN_WIDTH;
        optionDrawerStateId.value = 'floating';
    };

    // Option Drawer - Toggle
    const toggleOptionDrawer = (): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            optionDrawerStateId.value = 'expanded';
            optionDrawerWidthId.value = optionDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
            optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH;
            optionDrawerMenuWidth.value = optionDrawerWidth.value;
            window.localStorage.setItem(LOCAL_STORAGE_OPTION_DRAWER_WIDTH_ID, optionDrawerWidthId.value);
            return;
        }
        optionDrawerStateId.value === 'collapsed' ? floatOptionDrawer() : collapseOptionDrawer();
    };

    // Session - Properties & Variables
    const sessionExpiresIn = ref<number>(0);
    const sessionIsReauthorising = ref<boolean>(false);
    const sessionLifetime = ref<number>(0);
    const sessionUpdatesArePending = ref<boolean>(false);
    const sessionUser = shallowRef<User | false | undefined>(undefined);

    // Tool Components - Properties & Variables
    const toolDrawerPanelId = ref<string | undefined>(window.localStorage.getItem(LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID) || undefined);
    const toolDrawerStateId = ref<string>(getDrawerStateId(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID));
    const toolDrawerWidthId = ref<string>(getDrawerWidthId(LOCAL_STORAGE_TOOL_DRAWER_WIDTH_ID));
    const toolDrawerWidth = ref<number>(toolDrawerWidthId.value === 'narrow' ? DRAWER_MEDIAN_WIDTH : DRAWER_MAXIMUM_WIDTH);
    const toolMenuDataActionsEnabled = ref<boolean>(false);
    const toolMenuDropdownIsOpen = ref<boolean>(false);

    // Tool Components - Collapse
    const collapseToolComponents = (): void => {
        toolMenuDropdownIsOpen.value = false;
        toolDrawerPanelId.value = undefined;
        toolDrawerStateId.value = 'collapsed';
    };

    // Tool Components - Enable Menu Data Actions
    const enableToolComponentsMenuDataActions = (enabled: boolean): void => {
        navigationDepth.value = 0;
        toolMenuDataActionsEnabled.value = enabled;
        // hideWorkbenchMask(); // TODO
    };

    // Tool Components - Handle Menu Tab Change
    const handleToolComponentsMenuTabChange = (toolPanelId: ToolPanelId | undefined): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            if (toolPanelId === undefined || toolDrawerPanelId.value === toolPanelId) {
                window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
                window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID, (toolDrawerStateId.value = 'collapsed'));
                return;
            }
            window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
            window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID, (toolDrawerStateId.value = 'expanded'));
            return;
        }
        if (toolPanelId === undefined) {
            collapseToolComponents();
            window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
            window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID, toolDrawerStateId.value);
            return;
        }
        toolDrawerStateId.value = 'floating';
        window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
        window.localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID, 'expanded');
    };

    // Tool Components - Resize
    const resizeToolComponents = (): void => {
        toolDrawerWidthId.value = toolDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
        toolDrawerWidth.value = toolDrawerWidthId.value === 'narrow' ? DRAWER_MEDIAN_WIDTH : DRAWER_MAXIMUM_WIDTH;
        localStorage.setItem(LOCAL_STORAGE_TOOL_DRAWER_WIDTH_ID, toolDrawerWidthId.value);
    };

    // Tool Components - Toggle
    const toggleToolComponents = (): void => {
        if (toolMenuDropdownIsOpen.value || toolDrawerPanelId.value) {
            toolMenuDropdownIsOpen.value = false;
            handleToolComponentsMenuTabChange(undefined);
            return;
        }
        toolDrawerStateId.value = 'floating';
        toolMenuDropdownIsOpen.value = true;
    };

    // Window - Properties & Variables
    const windowWidth = ref<number>(0);

    // Window - Handle Width Change
    const handleWindowWidthChange = (width: number): void => {
        if (width >= WINDOW_BREAK_POINT) {
            if (windowWidth.value < WINDOW_BREAK_POINT) {
                // Handle new width  greater than or equal to the breakpoint and prior width less than it.
                optionDrawerStateId.value = 'expanded';
                optionDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE_OPTION_DRAWER_WIDTH_ID);
                optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH;
                optionDrawerMenuWidth.value = optionDrawerWidth.value;
                if (toolDrawerStateId.value === 'floating') {
                    toolDrawerStateId.value = 'expanded';
                }
                toolDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE_TOOL_DRAWER_WIDTH_ID);
                toolMenuDropdownIsOpen.value = false;
            }
            windowWidth.value = width;
            return;
        }
        if (windowWidth.value === 0 || windowWidth.value >= WINDOW_BREAK_POINT) {
            // Handle prior width unset or new width less than the breakpoint and prior width greater than or equal to it.
            collapseOptionDrawer();
            toolDrawerStateId.value = getDrawerStateId(LOCAL_STORAGE_TOOL_DRAWER_STATE_ID);
            if (toolDrawerStateId.value === 'expanded') {
                toolDrawerStateId.value = 'floating';
                toolDrawerWidthId.value = 'narrow';
            }
        }
        windowWidth.value = width;
    };

    // Workbench - Properties & Variables
    const workbenchIsEUInstance = ref<boolean | undefined>(undefined);
    const workbenchIsLoading = ref<boolean>(false);
    const workbenchLastDeployedVersion = ref<string | undefined>(undefined);
    const workbenchMaskIsActive = ref<boolean>(false);
    const workbenchModuleIsLoading = ref<boolean>(false);

    let workbenchMaskTimeout: number | undefined = undefined;
    workbenchMaskTimeout = window.setTimeout((): void => {
        workbenchIsLoading.value = true;
    }, DEFAULT_DELAY);
    const clearWorkbenchMaskTimeout = (): void => {
        window.clearTimeout(workbenchMaskTimeout);
        workbenchIsLoading.value = false;
        workbenchMaskTimeout = undefined;
    };

    return {
        // Properties
        alertConfigs,
        authFlowUIInputErrors,
        authFlowUIState,
        appearance,
        connectionConfigs,
        internetConnectionIsOnline,
        navigationDepth,
        optionDrawerPanelId,
        optionDrawerStateId,
        optionDrawerMenuWidth,
        optionDrawerWidth,
        optionDrawerWidthId,
        sessionExpiresIn,
        sessionIsReauthorising,
        sessionLifetime,
        sessionUpdatesArePending,
        sessionUser,
        toolDrawerPanelId,
        toolDrawerStateId,
        toolDrawerWidth,
        toolDrawerWidthId,
        toolMenuDataActionsEnabled,
        toolMenuDropdownIsOpen,
        workbenchIsEUInstance,
        workbenchIsLoading,
        workbenchLastDeployedVersion,
        workbenchMaskIsActive,
        workbenchModuleIsLoading,

        // Actions
        applyAppearanceUsingId,
        clearWorkbenchMaskTimeout,
        collapseOptionDrawer,
        collapseToolComponents,
        enableToolComponentsMenuDataActions,
        handleToolComponentsMenuTabChange,
        handleWindowWidthChange,
        resizeToolComponents,
        showAlert,
        toggleOptionDrawer,
        toggleToolComponents
    };
});
