<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Button from '@/components/Button.vue';
import DataActions from './DataActions.vue';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import ToolTabs from './ToolTabs.vue';
import UIActions from './UIActions.vue';

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const optionDrawerStateId = computed<string>(() => mainStore.optionDrawerStateId);
const toolDrawerPanelId = computed<string | undefined>(() => mainStore.toolDrawerPanelId);
const toolDrawerStateId = computed<string>(() => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed<number>(() => mainStore.toolDrawerWidth);
const toolMenuDropdownIsOpen = computed<boolean>(() => mainStore.toolMenuDropdownIsOpen);

// UI Event Handlers - Toggle
const handelToggle = (): void => {
    mainStore.toggleToolComponents();
};
</script>

<template>
    <div class="max-w-[calc(100%-16px)] bg-transparent dark:bg-transparent">
        <!-- Expanded Tool Menu -->
        <div class="h-14 items-center" :class="optionDrawerStateId === 'expanded' ? 'flex gap-x-2' : 'hidden'">
            <!-- Actions -->
            <div class="flex gap-x-2 pl-2">
                <DataActions />
                <div class="h-10 w-px bg-divider-light dark:bg-divider-dark" />
                <UIActions />
            </div>

            <!-- Vertical Divider -->
            <div v-if="!toolDrawerPanelId" class="h-10 w-px bg-divider-light dark:bg-divider-dark" />

            <!-- Tool Tabs -->
            <ToolTabs
                class="pr-4"
                :class="`${toolDrawerPanelId ? 'ml-2 border-l border-separator-light bg-surface-light pl-4 dark:border-separator-dark dark:bg-surface-dark' : ''} ${toolDrawerStateId === 'floating' ? '' : ''}`"
                :style="{ width: `${toolDrawerPanelId ? `${toolDrawerWidth}px` : 'unset'}` }"
            />
        </div>

        <!-- Contracted Tool Menu -->
        <div :class="optionDrawerStateId === 'expanded' ? 'hidden' : 'flex flex-col'">
            <!-- Tool Tabs & Toggle -->
            <div
                class="flex h-14 max-w-full items-center border-l border-r border-t pl-4"
                :class="`${toolMenuDropdownIsOpen || toolDrawerPanelId ? 'border-separator-light bg-surface-light dark:border-separator-dark dark:bg-surface-dark' : 'border-transparent dark:border-transparent'}`"
                :style="{ width: `${toolDrawerPanelId ? `${toolDrawerWidth}px` : 'unset'}` }"
            >
                <!-- Tool Tabs -->
                <ToolTabs v-if="toolMenuDropdownIsOpen || toolDrawerPanelId" class="overflow-x-scroll pr-2" />

                <!-- Toggle -->
                <Button class="mr-4" type="option" square @click.stop="handelToggle()">
                    <div v-html="actionIcons.toggleSVG" class="flex h-9 w-9 items-center justify-center" />
                </Button>
            </div>

            <!-- Actions -->
            <div v-if="toolMenuDropdownIsOpen" class="rounded-bl-md border-b border-l border-r border-separator-light bg-surface-light px-4 dark:border-divider-dark dark:bg-surface-dark">
                <HorizontalDivider />
                <div class="flex gap-x-2 py-2">
                    <DataActions />
                </div>
                <HorizontalDivider />
                <div class="flex gap-x-2 py-2">
                    <UIActions />
                </div>
            </div>
        </div>
    </div>
</template>
