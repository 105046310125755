<script lang="ts" setup>
// Dependencies - Vendor
import { computed, type PropType } from 'vue';

// Dependencies - Framework
import { optionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';
import type { BenchtopConfig, OptionConfig, WorkbenchConfig } from '../WorkbenchConfig';

// Dependencies - Component
import Button from '@/components/Button.vue';
import DropdownButton from '@/components/DropdownButton.vue';

// Dependencies - Data
import { workbenchConfigData } from '../WorkbenchConfig';

// Attributes, Emitted Events, Options, Properties & Slots
defineProps({
    benchtopConfig: { required: true, type: Object as PropType<BenchtopConfig> },
    labelsAreVisible: { required: true, type: Boolean },
    optionConfig: { required: true, type: Object as PropType<OptionConfig> }
});
defineEmits(['navigateToBenchtop', 'navigateToOption']);

// Global State
const mainStore = useMainStore();

//Constants
const WORKFLOW_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const optionDrawerPanelId = computed(() => mainStore.optionDrawerPanelId);
</script>

<template>
    <div class="mt-2 flex flex-none gap-x-1 rounded bg-backdrop-light dark:bg-backdrop-dark">
        <Button
            class="w-full flex-1"
            :class="labelsAreVisible ? 'rounded-r-none' : 'rounded-r'"
            :data-selected="optionConfig.id === optionDrawerPanelId"
            type="option"
            @click="$emit('navigateToOption', optionConfig, 'home')"
        >
            <div class="flex w-full items-center gap-x-2">
                <div v-html="optionIcons[`${benchtopConfig.id}SVG`]" class="flex h-9 w-9 flex-none items-center justify-center" />
                <div v-if="labelsAreVisible" class="truncate text-left">{{ $t(`${benchtopConfig.id}.label`) }}</div>
            </div>
        </Button>

        <DropdownButton
            v-if="labelsAreVisible"
            class="dp-dropdown-button flex-none rounded-l-none"
            :items="WORKFLOW_CONFIG.benchtops.map((benchtop) => ({ id: benchtop.id, label: $t(`${benchtop.id}.label`) }))"
            @click="(index: number) => $emit('navigateToBenchtop', index)"
        />
    </div>
</template>
