<script lang="ts" setup>
// Dependencies - Vendor
import { computed, onErrorCaptured } from 'vue';

// Dependencies - Framework
import { handleError } from '@/globals';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Alert, { type AlertConfig } from '@/components/Alert.vue';
import DefaultMask from '@/components/DefaultMask.vue';
import LoadingMask from '@/components/LoadingMask.vue';
import OptionDrawer from './optionDrawer/OptionDrawer.vue';
import ToolDrawer from '@/views/toolDrawer/ToolDrawer.vue';
import ToolMenu from './toolMenu/ToolMenu.vue';

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const alertConfigs = computed<AlertConfig[]>(() => mainStore.alertConfigs);
const navigationDepth = computed<number>(() => mainStore.navigationDepth); // TODO: Reimplement back button for PWA version.
const optionDrawerStateId = computed<string>(() => mainStore.optionDrawerStateId);
const optionDrawerWidth = computed<number>(() => mainStore.optionDrawerWidth);
const toolDrawerStateId = computed<string>(() => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed<number>(() => mainStore.toolDrawerWidth);
const toolMenuDropdownIsOpen = computed<boolean>(() => mainStore.toolMenuDropdownIsOpen);
const workbenchMaskIsActive = computed<boolean>(() => mainStore.workbenchMaskIsActive);
const workbenchModuleIsLoading = computed<boolean>(() => mainStore.workbenchModuleIsLoading);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => {
    return handleError(error, { locator: 'Workbench.1' });
});

// UI Event Handlers - Collapse Drawer
const handleCollapseDrawer = (): void => {
    if (mainStore.optionDrawerStateId === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    if (mainStore.toolDrawerStateId === 'floating') {
        mainStore.collapseToolComponents();
    }
};
</script>

<template>
    <!-- Layers -->
    <!-- z-10: Workbench Component -->
    <!-- z-20: View Header (see '@/components/ViewHeader.vue') -->
    <!-- z-30: Option Drawer & Tool Drawer (when NOT floating) -->
    <!-- z-40: Drawer Floating Mask -->
    <!-- z-50: Tool Menu, Option Drawer (when floating) & Tool Drawer (when floating) -->
    <!-- z-60: Workbench Mask & Dialog Panel -->
    <!-- z-70: Alert Panel -->

    <!-- Workbench -->
    <div class="h-full">
        <!-- Tool Menu - Positioned in top right corner. -->
        <ToolMenu class="fixed right-0 top-0 z-50 bg-backdrop-light dark:bg-backdrop-dark" />

        <!-- Body -->
        <div class="flex h-full">
            <!-- Option Drawer - Positioned on left side. -->
            <OptionDrawer class="flex-none" :class="optionDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />

            <!-- Workbench Component - Position in center. -->
            <div class="relative z-10 h-full" :style="{ width: `calc(100% - ${optionDrawerWidth}px - ${toolDrawerStateId === 'expanded' ? toolDrawerWidth : 0}px)` }">
                <RouterView v-slot="{ Component }">
                    <Transition name="fade">
                        <LoadingMask v-if="workbenchModuleIsLoading" />
                        <component :is="Component" v-else-if="Component" />
                    </Transition>
                </RouterView>
            </div>

            <!-- Tool Drawer - Position on right side. -->
            <ToolDrawer class="mt-14 flex-none" :class="toolDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />
        </div>
    </div>

    <!-- Drawer Mask - Only displayed when the option drawer is floating. -->
    <DefaultMask v-if="optionDrawerStateId === 'floating' || toolMenuDropdownIsOpen || toolDrawerStateId === 'floating'" class="z-40" @click="handleCollapseDrawer()" />

    <!-- Workbench Mask - Sits above all elements except the alert panel. -->
    <DefaultMask v-if="workbenchMaskIsActive" class="z-60" />

    <!-- Alerts - See '#alertPanel' in 'index.html' with 'z-index: 70'. -->
    <Teleport to="#alertPanel">
        <template v-for="alertConfig in alertConfigs" :key="alertConfig.id">
            <Alert :config="alertConfig" />
        </template>
    </Teleport>
</template>
