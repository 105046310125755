// Dependencies - Vendor
import type { AsyncComponentOptions } from 'vue';
import type { RouteLocationNormalizedGeneric } from 'vue-router';

// Dependencies - Framework
import type { ErrorContext } from '@datapos/datapos-share-core';

// Dependencies - Component
import LoadingMask from '@/components/LoadingMask.vue';
import UnavailableMask from '@/components/UnavailableMask.vue';

// Interfaces/Types - Component Type Configuration
type ComponentTypeConfig = { [key: string]: { base: string; variants: { [key: string]: string } } };

// Interfaces/Types - Dependency
export interface Dependency {
    name: string;
    repo: string;
    version: string;
}

// Interfaces/Types - Globals
interface Globals {
    appMonitor: typeof import('@/appMonitor') | undefined;
}

// Constants
export const DEFINE_ASYNC_COMPONENT_DELAY = 200;
export const DEFINE_ASYNC_COMPONENT_TIMEOUT = 5000;
export const DEFINE_ASYNC_COMPONENT_OPTIONS: Partial<AsyncComponentOptions> = {
    errorComponent: UnavailableMask,
    loadingComponent: LoadingMask,
    timeout: DEFINE_ASYNC_COMPONENT_TIMEOUT,
    onError(error, retry, fail, attempts) {
        console.log('defineAsyncComponent.onError', error, retry, fail, attempts); // TODO
    }
};
export const DISPLAY_SKELETON_DELAY = 200;
export const KIBIBYTE = 1024;

// Properties
export const globals: Globals = {
    appMonitor: undefined
};

// Application Monitor: Helpers - Initialise
export const initAppMonitor = async (module: typeof import('@/appMonitor')): Promise<void> => {
    await module.initialise();
    globals.appMonitor = module;
};

// Application Monitor: Helpers - Handle Error
export const handleError = (error?: unknown, context?: ErrorContext): boolean => {
    if (globals.appMonitor) {
        return globals.appMonitor.reportError(error, context);
    }
    const contextClause = `Context: ${context || 'unknown'}`;
    if (error instanceof Error) {
        alert(`${error.message} ${contextClause}`);
        return false;
    }
    if (error) {
        alert(`${String(error)} ${contextClause}`);
        return false;
    }
    alert(`Unknown error. ${contextClause}`);
    return false;
};

// Application Monitor: Helpers - Track Page View Event
export const trackPageViewEvent = (to: RouteLocationNormalizedGeneric): void => {
    if (globals.appMonitor) {
        globals.appMonitor.trackPageViewEvent(to);
    }
};

// String: Helpers - Build Component Classes
export const buildComponentClasses = (typeConfigs: ComponentTypeConfig, typePath: string): string => {
    const typePathSegments = typePath.split('.');
    const typeId = typePathSegments[0];
    const variantId = typePathSegments[1] || '';
    const typeConfig = typeConfigs[typeId];
    return `${typeConfig.base} ${typeConfig.variants[variantId]}`;
};

// String: Helpers - Format Size in Gigabytes (GB)
export const formatSizeInGB = (value: number): string => `${(value / (KIBIBYTE * 3)).toFixed(2)} GB`;

// UI: Helpers - Navigate to External Link
export const navigateToExternalLink = (url?: string): void => {
    if (url) {
        window.open(url, '_blank');
    }
};

// UI: Helpers - Reload Workbench
export const reloadWorkbench = (): void => {
    const appURL = new URL(window.location.href);
    const params = appURL.searchParams;
    params.delete('reload-timestamp');
    params.set('reload-timestamp', String(new Date().getTime()));
    window.location.href = appURL.toString();
};
