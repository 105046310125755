<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Application Infrastructure
import { actionIcons, authIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Button from '@/components/Button.vue';
import StatusIcon from '@/components/StatusIcon.vue';

// Interface/Types - Tool Panel Identifier
export type ToolPanelId = 'about' | 'account' | 'chat' | 'documentation' | 'index';

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed(() => mainStore.internetConnectionIsOnline);
const toolDrawerStateId = computed(() => mainStore.toolDrawerStateId);
const toolDrawerPanelId = computed(() => mainStore.toolDrawerPanelId);
const toolDrawerWidthId = computed(() => mainStore.toolDrawerWidthId);

// UI Event Handlers - Select Tab
const handleSelectTab = (toolPanelId: ToolPanelId): void => {
    mainStore.handleToolComponentsMenuTabChange(toolPanelId);
    mainStore.toolMenuDropdownIsOpen = false;
};

// UI Event Handlers - Toggle Resize
const handleToggleResize = (): void => {
    mainStore.resizeToolComponents();
};

// Utilities - Get Tab Color
const getTabColor = (tabId: ToolPanelId): string =>
    toolDrawerPanelId.value
        ? toolDrawerPanelId.value === tabId
            ? 'border-highlight-light dark:border-highlight-dark'
            : 'border-divider-light dark:border-divider-dark'
        : 'border-transparent dark:border-transparent';
</script>

<template>
    <!-- Wrapper -->
    <div class="flex h-14 w-full">
        <!-- Expand/Collapse -->
        <div v-if="toolDrawerPanelId && toolDrawerStateId !== 'floating'" class="mr-auto border-b-2 border-b-transparent pt-2 dark:border-b-transparent">
            <Button class="flex items-center justify-center" type="option" @click="handleToggleResize()">
                <div v-html="toolDrawerWidthId === 'wide' ? actionIcons.collapseSVG : actionIcons.expandSVG" class="h-9 w-9" style="transform: rotate(45deg); transform-origin: center" />
            </Button>
        </div>

        <div class="ml-auto flex gap-x-2">
            <!-- Documentation -->
            <div class="border-b-2 pt-2" :class="getTabColor('documentation')">
                <Button type="option" @click="handleSelectTab('documentation')">
                    <div v-html="actionIcons.toggleDocumentationSVG" class="flex h-9 w-9 items-center justify-center" />
                </Button>
            </div>

            <!-- Communication -->
            <div class="border-b-2 pt-2" :class="getTabColor('chat')">
                <Button type="option" @click="handleSelectTab('chat')">
                    <div v-html="actionIcons.toggleChatSVG" class="flex h-9 w-9 items-center justify-center" />
                </Button>
            </div>

            <!-- Account -->
            <div class="border-b-2 pt-2" :class="getTabColor('account')">
                <Button type="option" @click="handleSelectTab('account')">
                    <div v-html="authIcons.manageAccountSVG" class="flex h-9 w-9 items-center justify-center" />
                </Button>
            </div>

            <!-- About -->
            <div class="border-b-2 pt-2" :class="getTabColor('about')">
                <Button class="relative" type="option" @click="handleSelectTab('about')">
                    <div class="flex h-9 w-9 flex-none items-center justify-center"><img class="h-6 w-6" src="/dataPos.svg" /></div>
                    <StatusIcon v-if="!internetConnectionIsOnline" class="absolute -right-0.5 -top-0.5 z-70" :color="'warning'" />
                </Button>
            </div>
        </div>
    </div>
</template>
