// Dependencies - Framework
import { reloadWorkbench } from '@/globals';
import { t } from '@/locales';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Data
import { version } from '@/config.json';

// Constants
const TIMEOUT_DELAY = 5000;

// Non-Reactive Variables
let servicesWebSocket: WebSocket | undefined = undefined;

// Utilities - Compare Version Strings
const compareVersionStrings = (left: string, right: string) => {
    const leftSegments = left.split('.').map(Number);
    const rightSegments = right.split('.').map(Number);
    for (let i = 0; i < Math.max(leftSegments.length, rightSegments.length); i++) {
        const leftSegment = leftSegments[i] || 0;
        const rightSegment = rightSegments[i] || 0;
        if (leftSegment > rightSegment) {
            return 1;
        }
        if (leftSegment < rightSegment) {
            return -1;
        }
    }
    return 0;
};

// Utilities - Connect Services WebSocket
export const connectToWebSocket = () => {
    try {
        const mainStore = useMainStore();
        const wsURL = `wss://operations.datapositioning.app/services/websocket`;
        console.log(`Attempting to connect to WebSocket: ${wsURL}...`);
        servicesWebSocket = new WebSocket(wsURL);

        servicesWebSocket.onopen = () => console.log('WebSocket connection established.');

        servicesWebSocket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                const workbenchSession = data.find((session: Record<string, unknown>) => session.id === `workbench-${mainStore.workbenchIsEUInstance ? 'eu' : 'global'}`);
                if (!workbenchSession) {
                    return;
                }
                const newVersion = workbenchSession.data.version;
                if (compareVersionStrings(mainStore.workbenchLastDeployedVersion || version, newVersion) === -1) {
                    mainStore.workbenchLastDeployedVersion = newVersion;
                    // If installed version, or last seen version, is less than new version then show install alert.
                    mainStore.showAlert({
                        actionLabel: t('action.reload.label'),
                        actionCallback: async () => reloadWorkbench(),
                        message: t('versionManagement.available.message', { newVersion: newVersion }),
                        notes: t('versionManagement.available.notes', { currentVersion: version }),
                        typePath: 'default.neutral'
                    });
                }
            } catch (error) {
                console.log('Error parsing message.', error);
            }
        };

        servicesWebSocket.onclose = (event) => {
            console.log(`WebSocket closed. Code: ${event.code}.`);
            servicesWebSocket = undefined;
            setTimeout(connectToWebSocket, TIMEOUT_DELAY);
        };

        servicesWebSocket.onerror = (error) => {
            // TODO: Try and reconnect a limited number of times.
            console.log('Error occurred. Check error log for details.', error);
        };

        return servicesWebSocket;
    } catch (error) {
        console.log('Failed to create WebSocket. Check error log for details.', error);
    }
};
