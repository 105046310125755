<script lang="ts" setup>
// Dependencies - Vendor
import { onMounted, onUnmounted, type PropType, ref } from 'vue';

// Dependencies - Framework
import { alertIcons } from '@/assets/icons';
import { buildComponentClasses } from '@/globals';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Button from '@/components/Button.vue';

// Interfaces/Types - Alert Configuration
export interface AlertConfig {
    actionCallback?: (config: AlertConfig) => void;
    actionLabel?: string;
    categoryId?: 'deploy' | 'offline' | 'online' | 'session';
    expiresIn?: number;
    id?: string;
    message: string;
    notes?: string;
    occurrenceId?: string;
    typePath: 'default.caution' | 'default.negative' | 'default.neutral' | 'default.positive';
}

// Global State
const mainStore = useMainStore();

// Attributes, Emitted Events, Options, Properties & Slots
const props = defineProps({
    config: { required: true, type: Object as PropType<AlertConfig> }
});

// Constants
const CLASSES = buildComponentClasses(
    {
        default: {
            base: '',
            variants: {
                caution: 'text-yellow-500 dark:text-yellow-300',
                negative: 'text-red-500 dark:text-red-300',
                neutral: 'text-blue-500 dark:text-blue-300',
                positive: 'text-green-500 dark:text-green-300'
            }
        }
    },
    props.config.typePath
);
const EXPIRES_IN = props.config.expiresIn || 0;
const UPDATE_INTERVAL = 100;

// Non-Reactive Variables
let elapsedTime = 0;
let expiresInTimer: number | undefined = undefined;

// Reactive Variables & Watchers
const percentComplete = ref(0);

// Lifecycle Event Handlers
onMounted((): void => {
    EXPIRES_IN ? setTimer() : void 0;
});
onUnmounted((): void => {
    clearTimer();
});

// UI Event Handlers - Click
const handleClick = (): void => (EXPIRES_IN ? (expiresInTimer ? clearTimer() : setTimer()) : void 0);

// UI Event Handlers - Close
const handleClose = (alertConfig: AlertConfig): void => void mainStore.alertConfigs.splice(mainStore.alertConfigs.indexOf(alertConfig), 1);

// Utilities - Clear Timer
const clearTimer = (): void => {
    window.clearInterval(expiresInTimer);
    expiresInTimer = undefined;
};

// Utilities - Set Timer
const setTimer = (): void => {
    expiresInTimer = window.setInterval(() => {
        elapsedTime += UPDATE_INTERVAL;
        percentComplete.value = (elapsedTime / EXPIRES_IN) * 100;
        if (elapsedTime >= EXPIRES_IN) {
            window.clearInterval(expiresInTimer);
            handleClose(props.config);
        }
    }, UPDATE_INTERVAL);
};
</script>

<template>
    <div class="relative mt-3 overflow-hidden rounded-md border border-neutral-200 bg-surface-light dark:border-neutral-700 dark:bg-neutral-800 dark:bg-surface-dark" @click.stop="handleClick()">
        <!-- Message -->
        <div class="mt-3 flex w-full gap-x-2 px-4 leading-tight">
            <div v-html="alertIcons[`${config.typePath}SVG`]" class="h-6 w-6 flex-none items-center justify-center" :class="CLASSES" />
            <div class="dp-break-word mr-10 mt-0.5 flex-1 font-medium">{{ config.message }}</div>
            <Button class="absolute right-2 top-2 flex-none border-none" type="icon" @click.stop="handleClose(config)">
                <div v-html="alertIcons.closeSVG" class="h-6 w-6 items-center justify-center" />
            </Button>
        </div>

        <!-- Optional Notes & Action -->
        <div class="px-4 text-sm">
            <!-- Notes -->
            <div v-if="config.notes" class="dp-break-word whitespace-pre-wrap px-0.5">{{ config.notes }}</div>

            <!-- Action -->
            <div v-if="config.actionCallback && config.actionLabel" class="mt-3">
                <Button type="alert" @click.stop="config.actionCallback(config)">{{ config.actionLabel }}</Button>
            </div>
        </div>

        <!-- Expires In Progress Bar -->
        <div class="mt-3 h-1 w-full">
            <div class="h-full bg-neutral-300 dark:bg-neutral-600" :style="`width: ${percentComplete.toFixed(1)}%`" />
        </div>
    </div>
</template>
