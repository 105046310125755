<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import Button from '@/components/Button.vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineEmits(['action']);

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const toolMenuDataActionsEnabled = computed<boolean>(() => mainStore.toolMenuDataActionsEnabled);
</script>

<template>
    <!-- Add -->
    <Button :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'add')">
        <div class="flex items-center">
            <div v-html="actionIcons.addSVG" class="flex h-9 w-9 items-center justify-center" />
        </div>
    </Button>

    <!-- Save -->
    <Button :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'save')">
        <div class="flex items-center">
            <div v-html="actionIcons.saveSVG" class="flex h-9 w-9 items-center justify-center" />
        </div>
    </Button>

    <!-- Cancel -->
    <Button :disabled="!toolMenuDataActionsEnabled" type="option" @click="$emit('action', 'cancel')">
        <div class="flex items-center">
            <div v-html="actionIcons.cancelSVG" class="flex h-9 w-9 items-center justify-center" />
        </div>
    </Button>
</template>
