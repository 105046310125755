// Dependencies - Vendor
import { h } from 'vue';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

// Dependencies - Framework
import { useMainStore } from '@/stores/mainStore';
import { handleError, trackPageViewEvent } from '@/globals';

// Dependencies - Component
import UnavailableMask from '@/components/UnavailableMask.vue';
import Workbench from '@/views/Workbench.vue';

// Constants
const TIMEOUT_DELAY = 200;

// Variables
const routes: RouteRecordRaw = {
    path: '/',
    component: Workbench,
    children: [
        { path: '', name: 'default', component: () => import('@/views/workflow/dashboard/WorkflowDashboard.vue') },
        {
            path: 'administrator',
            name: 'administrator',
            children: [{ path: '', name: 'administratorDefault', component: () => import('@/views/administrator/dashboard/AdministratorDashboard.vue') }]
        },
        { path: 'partner', name: 'partner', children: [{ path: '', name: 'partnerDefault', component: () => import('@/views/partner/dashboard/PartnerDashboard.vue') }] },
        {
            path: 'workflow',
            name: 'workflow',
            children: [
                {
                    path: '',
                    name: 'workflowDefault',
                    component: () => import('@/views/workflow/dashboard/WorkflowDashboard.vue')
                },
                {
                    path: 'establishDataViews',
                    children: [
                        {
                            path: '',
                            component: () =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve(
                                            import('@/views/workflow/establishDataViews/EstablishDataViews.vue')
                                                .then((component) => {
                                                    // throw new Error('My loading error');
                                                    return component;
                                                })
                                                .catch((error) => h(UnavailableMask, { error }))
                                        );
                                    }, 0);
                                })
                        }
                        // { path: 'steps/:dataViewId?', component: () => import('@/views/workflow/establishDataViews/EstablishDataViewsSteps.vue') }
                    ]
                },
                {
                    path: 'assembleDimensions',
                    children: [
                        { path: '', component: () => import('@/views/workflow/assembleDimensions/AssembleDimensions.vue') }
                        // { path: 'steps/:dimensionId?', component: () => import('@/views/workflow/assembleDimensions/AssembleDimensionsSteps.vue') }
                    ]
                },
                {
                    path: 'contextualiseData',
                    children: [
                        { path: '', component: () => import('@/views/workflow/contextualiseData/ContextualiseData.vue') }
                        // { path: 'steps/:eventQueryId?', component: () => import('@/views/workflow/contextualiseData/ContextualiseDataSteps.vue') }
                    ]
                },
                { path: 'exploreResults', children: [{ path: '', component: () => import('@/views/workflow/exploreResults/ExploreResults.vue') }] },
                { path: 'buildDataApps', children: [{ path: '', component: () => import('@/views/workflow/buildDataApps/BuildDataApps.vue') }] },
                // { path: 'connections/:connectionId?', component: () => import('@/views/workflow/connections/Connections.vue') },
                // { path: 'contexts/:contextId?', component: () => import('@/views/workflow/contexts/Contexts.vue') }
                { path: 'manageComponents', component: () => import('@/views/workflow/manageComponents/ManageComponents.vue') }
                // { path: 'contexts/:contextId/:areaId', component: () => import('@/views/workflow/contexts/ContextArea.vue') }
            ]
        }
    ]
};
let workbenchMaskTimeout: number | undefined = undefined;

// Router - Create
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [routes, { path: '/:catchAll(.*)*', redirect: '/' }],
    scrollBehavior: (to, from, savedPosition) => savedPosition || { top: 0 }
});
router.onError((error, to): void => {
    handleError(error, { locator: 'router.1', routerTo: to });
});
router.beforeEach(async (to): Promise<void> => {
    const mainStore = useMainStore();
    if (!mainStore.workbenchIsLoading) {
        workbenchMaskTimeout = window.setTimeout(() => (mainStore.workbenchModuleIsLoading = true), TIMEOUT_DELAY);
    }
    trackPageViewEvent(to);
});
router.afterEach((): void => {
    window.clearTimeout(workbenchMaskTimeout);
    workbenchMaskTimeout = undefined;
    const mainStore = useMainStore();
    mainStore.clearWorkbenchMaskTimeout();
    mainStore.workbenchModuleIsLoading = false;
});

// Router - Expose
export default router;
