// Dependencies - Vendor
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Dependencies - Framework
import '@/assets/main.css';
import { initTranslations } from '@/locales';
import router from '@/router';
import { handleError, initAppMonitor } from '@/globals';

// Dependencies - Component
import App from '@/App.vue';

// Load Application
try {
    // Create a new Vue application instance and register plugins.
    const application = createApp(App); // Create the main Vue instance using the root component.
    application.use(createPinia()); // Register Pinia for centralized state management. 'useMainStore()' in 'src/stores/mainStore.ts' is now available for use.
    application.use(router); // Register the router to handle navigation.

    // Initialize translations before proceeding with application initialization.
    initTranslations(application).then((): void => {
        try {
            // Asynchronously load and initialize the application monitor without blocking application startup.
            // 'src/global.ts' provides helpers for communicating with the application monitor. These are required as the monitor may not have completed loading if we access it during the startup process.
            // The application monitor is also responsible for asynchronously loading the session manager.
            import('@/appMonitor').then((module) => initAppMonitor(module));

            application.mount('#app'); // Mount the application to the DOM. This will begin before the application monitor and session manager have completed loading.
        } catch (error) {
            handleError(error, { locator: 'main.2' }); // Report application load error.
        }
    });
} catch (error) {
    handleError(error, { locator: 'main.1' }); // Report application load error.
}
