<script setup lang="ts">
// Dependencies - Vendor
import { computed, onMounted, ref } from 'vue';

// Dependencies - Framework
import { useMainStore } from '@/stores/mainStore';
import { reloadWorkbench } from '@/globals';

// Dependencies - Component
import Button from '@/components/Button.vue';

// Dependencies - Data
import { version } from '@/config.json';

// Attributes, Emitted Events, Options, Properties & Slots
const props = defineProps({
    error: { required: false, type: Object }
});

// Global State
const mainStore = useMainStore();

// Constants
const MODULE_LABEL_MAP: Record<string, string> = {
    AboutPanel: 'About Module',
    AccountPanel: 'Account Module',
    ChatPanel: 'Chat Module',
    DocumentationPanel: 'Documentation Module'
};

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed(() => mainStore.internetConnectionIsOnline);
const message = ref('');

// Lifecycle Event Handlers
onMounted(() => {
    message.value = buildMessage();
});

// Utilities - Build Message
const buildMessage = (): string => {
    if (!props.error || !props.error.message) {
        return `Failed to load module for unspecified reason.<br/><br/>Click the Reload button to restart the Workbench.`;
    }

    let errorMessage = `${props.error.message}${props.error.message.endsWith('.') ? '' : '.'}`;
    errorMessage = errorMessage.replace(/(https?:\/\/[^\s]+)/g, (url) => `'<span class="break-all">${url}</span>'`);

    const matchMap = errorMessage.match(/\/assets\/([A-Za-z0-9]+)[-.]/);
    const moduleName = matchMap ? matchMap[1] : undefined;
    const moduleLabel = moduleName ? MODULE_LABEL_MAP[moduleName] : 'module';

    if (mainStore.workbenchLastDeployedVersion && mainStore.workbenchLastDeployedVersion !== version && errorMessage.startsWith('Failed to fetch dynamically imported module:')) {
        return `You are using a old version (${version}) of the Workbench.<br/><br/>The '${moduleLabel}' was not previously downloaded and is no longer available.<br/><br/>Click the Reload button to update the Workbench to the latest version (${mainStore.workbenchLastDeployedVersion}).`;
    }

    if (!internetConnectionIsOnline.value) {
        return `Your internet connection appears to be offline.<br/><br/>The '${moduleLabel}' can not be downloaded at this time.<br/><br/>Ensure you have an active internet connection and click the Reload button to update the Workbench and download the module.`;
    }

    return `${errorMessage}<br/><br/>Click the Reload button to restart the Workbench.`;
};
</script>

<template>
    <div class="flex h-full flex-col items-center justify-center overflow-y-scroll overscroll-y-none bg-surface-light p-4 text-caption-light dark:bg-surface-dark dark:text-caption-dark">
        <div class="flex max-w-80 flex-col items-center gap-y-4">
            <div class="text-sm" v-html="message" />
            <Button class="flex-none" type="alert" @click.stop="reloadWorkbench()">Reload</Button>
        </div>
    </div>
</template>
